import React, { useCallback, useRef } from 'react';
import {
  StyledReviewsContainer,
  StyledReviewsContent,
  StyledReviewsTitle,
} from 'layout/reviews/reviews.styles';
import { Typography } from 'core/typography';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Rating } from 'core/rating';
import { usePaging } from 'utils/hooks/paging.hook';
import { Paging } from 'core/paging';
import { ReviewsProps } from 'layout/reviews/reviews.props';
import { Review } from 'types/review.type';
import { ReviewItem } from 'layout/reviews/review-item';
import { Icon } from 'core/icon';
import { ReviewsPresets } from 'layout/reviews/reviews.presets';

export const Reviews = (props: ReviewsProps) => {
  const { reviews, total, color, textColor } = Object.assign(
    {},
    ReviewsPresets,
    props,
  );

  const containerRef = useRef<HTMLDivElement>();

  const {
    records,
    total: totalPages,
    currentPage,
    pageCount,
    goToPage,
  } = usePaging({
    data: reviews,
    pageSize: 5,
  });

  const onPageChange = useCallback(
    (page: number) => {
      goToPage(page);

      if (containerRef.current) {
        containerRef.current.scrollIntoView();
      }
    },
    [containerRef.current],
  );

  return (
    <StyledReviewsContainer ref={containerRef}>
      <StyledReviewsTitle>
        <Typography variant="h2" tx="common.reviews.title" color={color} />
        <TypographyWithIcon
          variant="p3"
          tx="common.reviews.poweredBy"
          icon={<Icon name="yotpo" color="black" size="2.7rem" />}
          iconPosition="right"
        />
      </StyledReviewsTitle>

      <TypographyWithIcon
        tx="common.reviews.totalReviews"
        txValues={{ total: reviews.length }}
        icon={<Rating rating={total} size="2.4rem" />}
        color={textColor}
      />

      <StyledReviewsContent>
        {records.map((review: Review) => (
          <ReviewItem
            key={review.id}
            review={review}
            color={color}
            textColor={textColor}
          />
        ))}
      </StyledReviewsContent>

      <Paging
        total={totalPages}
        currentPage={currentPage}
        color={color}
        pageCount={pageCount}
        onPageChange={onPageChange}
      />
    </StyledReviewsContainer>
  );
};
