import DOMPurify from 'dompurify';

export const extractSentences = (
  text: string,
  numberOfSentences: number = 1,
): string => {
  const extracted = (text ?? '')
    .split('.')
    .slice(0, numberOfSentences)
    .join('.');

  return `${extracted}.`;
};

export const decodeHTMLString = htmlString => {
  return DOMPurify.sanitize ? DOMPurify.sanitize(htmlString) : '';
};
