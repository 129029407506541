import React, { useEffect, useMemo, useState } from 'react';
import { PagingProps } from 'core/paging/paging.props';
import { PagingPresets } from 'core/paging/paging.presets';
import { StackedContainer } from 'layout/stacked-container';
import range from 'lodash/range';
import { Typography } from 'core/typography';
import { StyledDotsPage, StyledPageNumber } from 'core/paging/paging.styles';
import { v4 as uuid4 } from 'uuid';
import { useMediaLarge } from 'utils/hooks';

export const Paging = (props: PagingProps) => {
  const { pageCount, currentPage, total, color, onPageChange } = Object.assign(
    {},
    PagingPresets,
    props,
  );
  const large = useMediaLarge();
  const [visiblePages, setVisiblePages] = useState([]);

  const pages = useMemo(() => range(0, pageCount), [pageCount]);

  useEffect(() => {
    if (pages?.length) {
      if (pages.length > 7) {
        let left;
        let right;

        const visibleItemsCount = large ? 2 : 1;

        left =
          currentPage >= 3
            ? [
                0,
                uuid4(),
                ...range(currentPage - visibleItemsCount, currentPage),
              ]
            : range(0, currentPage);

        right =
          currentPage > pages.length - (large ? 5 : 4)
            ? range(currentPage + 1, pages.length)
            : [
                ...range(currentPage + 1, currentPage + 1 + visibleItemsCount),
                uuid4(),
                pages.length - 1,
              ];

        setVisiblePages([...left, currentPage, ...right]);
      } else {
        setVisiblePages(range(0, pages.length));
      }
    }
  }, [currentPage, pages?.length, large]);

  const changePage = (page: number): void => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  return (
    <StackedContainer
      flow="column"
      justifyContent="center"
      gap={{ lg: 's3', sm: 's1' }}
      hidden={!total || pageCount < 2}
      padding="unset"
      height="auto">
      {visiblePages.map((position: number | string) =>
        typeof position === 'string' ? (
          <StyledDotsPage key={position}>...</StyledDotsPage>
        ) : (
          <StyledPageNumber
            key={position}
            color={position === currentPage ? color : 'transparent'}
            onClick={() => changePage(position)}>
            <Typography
              variant="p3"
              color={position === currentPage ? 'white' : 'grey'}
              text={position + 1}
            />
          </StyledPageNumber>
        ),
      )}
    </StackedContainer>
  );
};
