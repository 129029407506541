import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledReviewsContainer = styled.div`
  display: grid;
  row-gap: 4rem;
`;

export const StyledReviewsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledReviewHeading = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    column-gap: ${theme.spacing.s1};
  `}
`;

export const StyledReviewsContent = styled.div`
  ${({ theme }) => css`
    display: grid;
    row-gap: ${theme.spacing.s5};

    ${media.lessThan('md')`
        row-gap: ${theme.spacing.s4};
    `}

    ${media.lessThan('sm')`
        row-gap: ${theme.spacing.s3};
    `}
  `}
`;

export const StyledReview = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.s1};
    border-top: 1px solid ${theme.colors.lightGrey};
    display: grid;
    row-gap: ${theme.spacing.s1};

    &:last-of-type {
      border-bottom: 1px solid ${theme.colors.lightGrey};
      padding-bottom: ${theme.spacing.s5};

      ${media.lessThan('md')`
        padding-bottom: ${theme.spacing.s4};
      `}

      ${media.lessThan('sm')`
        padding-bottom: ${theme.spacing.s3};
      `}
    }
  `}
`;

export const StyledImagesContainer = styled.div<{ scrollable: boolean }>`
  ${({ theme, scrollable }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, 20rem);
    column-gap: 1rem;
    overflow: auto;
    grid-auto-flow: column;
    grid-auto-columns: 20rem;
    padding-bottom: ${scrollable ? theme.spacing.s3 : 'unset'};
    cursor: pointer;
    margin-top: 2rem;

    ${media.lessThan('sm')`
      grid-template-columns: repeat(auto-fit, 10rem);
      grid-auto-columns: 10rem;    
      padding-bottom: ${scrollable ? theme.spacing.s2 : 'unset'};
      margin-top: 1rem;
    `}

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 4px;
      width: 0;
      background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 44%,
        ${theme.colors.lightGrey} 69%,
        transparent 55%,
        transparent 100%
      );
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${theme.colors.black};
      visibility: collapse;

      ${media.lessThan('md')`
         visibility: visible;
      `}
    }

    &:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  `}
`;
