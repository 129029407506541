import React, { memo } from 'react';
import { RatingStarProps } from 'core/rating/rating-star.props';
import { Icon } from 'core/icon/icon';

const half = 0.5;
const quarter = 0.25;

export const RatingStar = memo((props: RatingStarProps) => {
  const { position, rating, color, size } = props;

  const innerPosition = position + 1;

  if (rating + quarter >= innerPosition) {
    return <Icon name="star" color={color} size={size} />;
  }

  if (rating + quarter >= innerPosition - half) {
    return <Icon name="star-half" color={color} size={size} />;
  }

  return <Icon name="star-outlined" color={color} size={size} />;
});
