import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAsync } from 'react-use';

const initial = {
  data: [],
  currentPage: 0,
  pageSize: 30,
};

export interface UsePagingProps<T> {
  data: T[];
  pageSize?: number;
  saveInRoute?: boolean;
}

export interface UsePagingResponse<T> {
  records: T[];
  currentPage: number;
  pageSize: number;
  pageCount: number;
  total: number;
  next: () => void;
  previous: () => void;
  goToPage: (page: number) => void;
}

export function usePaging<T = any>(
  props: UsePagingProps<T>,
): UsePagingResponse<T> {
  {
    const {
      data: stateRecords,
      currentPage,
      pageSize: statePageSize,
    } = Object.assign({}, initial, props);

    const [innerPage, setInnerPage] = useState(currentPage);

    const router = useRouter();

    const records = stateRecords.slice(
      innerPage * statePageSize,
      innerPage * statePageSize + statePageSize,
    );

    const goToPage = (page: number) => {
      setInnerPage(page);
    };

    useEffect(() => {
      if (props.saveInRoute) {
        const params = new URLSearchParams(router.asPath.split('?')[1]);
        const page = params.get('page');

        if (page) {
          setTimeout(() => {
            goToPage(+page - 1);
          }, 0);
        }
      }
    }, []);

    useAsync(async () => {
      if (props.saveInRoute) {
        const params = new URLSearchParams(router.asPath.split('?')[1]);

        if (innerPage) {
          params.set('page', String(innerPage + 1));
        } else {
          params.delete('page');
        }

        const paramsStr = params.toString();

        await router.replace(
          router.pathname,
          `${router.asPath.split('?')[0]}${paramsStr ? `?${paramsStr}` : ''}`,
        );
      }
    }, [innerPage, router.asPath, props.saveInRoute]);

    const next = () => {
      setInnerPage(
        Math.min(
          innerPage + 1,
          Math.ceil(stateRecords.length / statePageSize - 1),
        ),
      );
    };

    const previous = () => {
      setInnerPage(Math.max(0, innerPage - 1));
    };

    return {
      records,
      currentPage: innerPage,
      pageSize: statePageSize ?? 0,
      pageCount: Math.ceil(stateRecords.length / statePageSize),
      total: props.data?.length ?? 0,
      next,
      previous,
      goToPage,
    };
  }
}
