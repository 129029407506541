import React from 'react';
import { RatingProps } from 'core/rating/rating.props';
import { RatingPresets } from 'core/rating/rating.presets';
import { StyledRating } from './rating.styles';
import { range } from 'lodash';
import { RatingStar } from 'core/rating/rating-star';

export const Rating = (props: RatingProps) => {
  const { stars, rating, color, size } = Object.assign(
    {},
    RatingPresets,
    props,
  );

  return (
    <StyledRating stars={stars}>
      {range(0, stars).map(item => (
        <RatingStar
          key={item}
          position={item}
          rating={rating}
          color={color}
          size={size}
        />
      ))}
    </StyledRating>
  );
};
