import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  StyledImagesContainer,
  StyledReview,
  StyledReviewHeading,
} from 'layout/reviews/reviews.styles';
import { Typography } from 'core/typography';
import { formatDate } from 'utils/date';
import { Rating } from 'core/rating';
import { ReviewProps } from 'layout/reviews/reviews.props';
import { ImageContainer } from 'layout/image-container';
import { useDrag } from 'react-use-gesture';
import { decodeHTMLString } from 'utils/string';

export const ReviewItem = (props: ReviewProps) => {
  const { review, color, textColor } = props;

  const [description, setDescription] = useState(review?.content || '');

  const photosContainerRef = useRef<HTMLDivElement>();

  const bind = useDrag(
    async ({ direction: [mxDir] }) => {
      photosContainerRef.current.scroll({
        left: photosContainerRef.current.scrollLeft + mxDir * -10,
        behavior: 'auto',
      });
    },
    {
      axis: 'x',
      lockDirection: true,
    },
  );

  useEffect(() => {
    setDescription(decodeHTMLString(review.content));
  }, [review?.content]);

  const scrollablePhotos = useMemo(() => {
    if (photosContainerRef.current) {
      return (
        photosContainerRef.current.scrollWidth >
        photosContainerRef.current.clientWidth
      );
    }
    return false;
  }, [photosContainerRef.current]);

  return (
    <StyledReview key={review.id}>
      <StyledReviewHeading>
        {review.user.display_name &&
          !review.user.display_name.includes('@') && (
            <Typography
              text={review.user.display_name}
              textStyle="bold"
              color={textColor}
            />
          )}

        {review.verified_buyer && (
          <Typography tx="common.reviews.verifiedBuyer" color={textColor} />
        )}

        <Typography
          text={formatDate(review.created_at, 'MM/DD/YY')}
          color={textColor}
        />
      </StyledReviewHeading>

      <Rating rating={review.score} />

      <Typography htmlText={description} color={color} />

      <Typography
        tx="common.reviews.reviewedProduct"
        txValues={{ product: review.product.name }}
        color={textColor}
        variant="p3"
      />

      {!!review.images_data?.length && (
        <StyledImagesContainer
          {...bind()}
          ref={photosContainerRef}
          scrollable={scrollablePhotos}>
          {review.images_data.map(item => (
            <ImageContainer
              key={item.id}
              width={{ lg: '20rem', sm: '10rem' }}
              height={{ lg: '20rem', sm: '10rem' }}
              src={item.thumb_url}
              source="external"
            />
          ))}
        </StyledImagesContainer>
      )}
    </StyledReview>
  );
};
