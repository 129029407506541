import styled, { css } from 'styled-components';
import { getThemeColor } from 'styles/theme';

export const StyledPageNumber = styled.span<{ color: string }>`
  ${({ theme, color }) => css`
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.colors.lightGrey};
    background-color: ${getThemeColor(color)};
    cursor: pointer;
    user-select: none;
  `}
`;

export const StyledDotsPage = styled.div`
  ${({ theme }) => css`
    position: relative;
    top: -3px;
    color: ${theme.colors.grey};
    letter-spacing: 3px;
  `}
`;
