import styled, { css } from 'styled-components';

export const StyledRating = styled.div<{ stars: number }>`
  ${({ stars }) => css`
    display: inline-grid;
    grid-template-columns: repeat(${stars}, max-content);
    align-items: center;
    column-gap: 2px;
  `}
`;
